import React, {useState, useEffect} from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { useStaticQuery, graphql, Link } from "gatsby"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import PropertyCard from "gatsby-theme-starberry-lomondgroup/src/components/PropertyCard/FeatPropertyCard";
import Slider from "react-slick";
import $ from "jquery";

import "gatsby-theme-starberry-lomondgroup/src/components/Features/Features.scss";

import {hasMyaccount } from "../../common/constant"
import { UserObjectStoreProvider, getUserobjectData, isAuthenticated } from "@starberry/myaccount-website-utils";

//import {FeaturedProperties} from "../../common/queries/common_use_query"

const Features = ({Module, menu_slug}) => {
    
    //console.log("menu_slug", menu_slug);

    const [userObjects, setUserObjects] = useState({});
    const authuser = isAuthenticated()

    useEffect(() => {
    if (authuser && hasMyaccount) {
        const getUserObjects = async () => {
            try {
                const userObjects = await getUserobjectData()
                setUserObjects(userObjects.data.data)
            } catch (e) {
                console.log("e:", e)
            }
        }
        getUserObjects()
    }
    }, [])

    const data = useStaticQuery(graphql`
      query FeaturedPropertyQueryJohnshepherd{
        glstrapi {
            sales_buy_properties:properties(sort:"price:desc",where:{publish:true, department:"residential", search_type:"sales", status_in:["For Sale"]}, limit:12){
                id
                crm_id
                display_address
                address
                title
                slug
                department
                status
                search_type
                price
                price_qualifier
                bedroom
                bathroom
                parking
                reception
                images
                imagetransforms
                available_from
                extra
                property_type
                office_crm_id
                ggfx_results {
                    id
                    content_type
                    transforms
                    src_import_url
                    src_cftle
                    field
                }
            }
            sales_sold_properties:properties(sort:"price:asc",where:{publish:true, department:"residential", search_type:"sales", status_in:["Sale Agreed"]}, limit:12){
                id
                crm_id
                display_address
                address
                title
                slug
                department
                status
                search_type
                price
                price_qualifier
                bedroom
                bathroom
                parking
                reception
                images
                imagetransforms
                available_from
                extra
                property_type
                office_crm_id
                ggfx_results {
                    id
                    content_type
                    transforms
                    src_import_url
                    src_cftle
                    field
                }
            }
            sales_newhomes_properties:properties(sort:"price:asc",where:{publish:true, department:"residential", search_type:"sales", officeDepartment:"New Homes", status_in:["For Sale"]}, limit:12){
                id
                crm_id
                display_address
                address
                title
                slug
                department
                status
                search_type
                price
                price_qualifier
                bedroom
                bathroom
                parking
                reception
                images
                imagetransforms
                available_from
                extra
                property_type
                office_crm_id
                ggfx_results {
                    id
                    content_type
                    transforms
                    src_import_url
                    src_cftle
                    field
                }
            }
            land_properties:properties(sort:"price:asc",where:{publish:true, department:"residential", search_type:"sales", building_in:["Land", "land", "development-plot", "Development plot"]}){
                id
                crm_id
                display_address
                address
                title
                slug
                department
                status
                search_type
                price
                price_qualifier
                bedroom
                bathroom
                parking
                reception
                images
                imagetransforms
                available_from
                extra
                property_type
                office_crm_id
                ggfx_results {
                    id
                    content_type
                    transforms
                    src_import_url
                    src_cftle
                    field
                }
            }

            to_let_properties:properties(sort:"price:desc",where:{publish:true, department:"residential", search_type:"lettings", status_in:["To Let"]}, limit:12){
                id
                crm_id
                display_address
                address
                title
                slug
                department
                status
                search_type
                price
                price_qualifier
                bedroom
                bathroom
                parking
                reception
                images
                imagetransforms
                available_from
                extra
                property_type
                office_crm_id
                ggfx_results {
                    id
                    content_type
                    transforms
                    src_import_url
                    src_cftle
                    field
                }
            }
            let_properties:properties(sort:"price:desc",where:{publish:true, department:"residential", search_type:"lettings", status_in:["Let Agreed"]}, limit:12){
                id
                crm_id
                display_address
                address
                title
                slug
                department
                status
                search_type
                price
                price_qualifier
                bedroom
                bathroom
                parking
                reception
                images
                imagetransforms
                available_from
                extra
                property_type
                office_crm_id
                ggfx_results {
                    id
                    content_type
                    transforms
                    src_import_url
                    src_cftle
                    field
                }
            }
            student_let_properties:properties(sort:"price:desc",where:{publish:true, department:"residential", search_type:"lettings", officeDepartment_in:["Student", "LSW"], status_in:["To Let"]}, limit:12){
                id
                crm_id
                display_address
                address
                title
                slug
                department
                status
                search_type
                price
                price_qualifier
                bedroom
                bathroom
                parking
                reception
                images
                imagetransforms
                available_from
                extra
                property_type
                office_crm_id
                ggfx_results {
                    id
                    content_type
                    transforms
                    src_import_url
                    src_cftle
                    field
                }
            }

            auction_properties:properties(sort:"price:desc",where:{publish:true, department:"auction", search_type:"sales", status:"For Sale"}, limit:12){
                id
                crm_id
                display_address
                address
                title
                slug
                department
                status
                search_type
                price
                price_qualifier
                bedroom
                bathroom
                parking
                reception
                images
                imagetransforms
                available_from
                extra
                property_type
                office_crm_id
                ggfx_results {
                    id
                    content_type
                    transforms
                    src_import_url
                    src_cftle
                    field
                }
            }

            auction_sold:properties(sort:"price:desc",where:{publish:true, department:"auction", search_type:"sales", status:"Sale Agreed"}, limit:12){
                id
                crm_id
                display_address
                address
                title
                slug
                department
                status
                search_type
                price
                price_qualifier
                bedroom
                bathroom
                parking
                reception
                images
                imagetransforms
                available_from
                extra
                property_type
                office_crm_id
                ggfx_results {
                    id
                    content_type
                    transforms
                    src_import_url
                    src_cftle
                    field
                }
            }

            investment_properties:properties(sort:"price:desc",where:{publish:true, officeDepartment:"investments", status_in:["For Sale"]}, limit:12){
                id
                crm_id
                display_address
                address
                title
                slug
                department
                status
                search_type
                price
                price_qualifier
                bedroom
                bathroom
                parking
                reception
                images
                imagetransforms
                available_from
                extra
                property_type
                office_crm_id
                officeDepartment
                selling_info
                ggfx_results {
                    id
                    content_type
                    transforms
                    src_import_url
                    src_cftle
                    field
                }
            }
        }
    }`)

    // var sales_properties = menu_slug?.includes("sell") ? data.glstrapi?.sales_sold_properties : menu_slug?.includes("new-homes") ? data.glstrapi?.sales_newhomes_properties : menu_slug?.includes("guide-to-selling-property") ? data.glstrapi?.sales_sold_properties : data.glstrapi?.sales_buy_properties;

    var sales_properties = data.glstrapi?.sales_buy_properties;
    var sold_properties = data.glstrapi?.sales_sold_properties;

    //console.log("sales_sold_properties", data.glstrapi?.sales_sold_properties)

    var to_let_properties = data.glstrapi?.to_let_properties;
    var let_properties = data.glstrapi?.let_properties;
    var student_let_properties = data.glstrapi?.student_let_properties;

    var auction_properties = data.glstrapi?.auction_properties;
    var auction_sold = data.glstrapi?.auction_sold;
    var land_properties = data.glstrapi?.land_properties;
    var development_plot_properties = data.glstrapi?.development_plot_properties;
    var investment_properties = data.glstrapi?.investment_properties;

    var srch_type = (menu_slug?.includes("rent") || menu_slug?.includes("landlords")) ? "lettings" : "sales"

    const[department, setDepartment] = useState("residential")
    const[search_type, setSearchType] = useState("sales")
    const[properties_list, setPropertiesList] = useState(sales_properties)

    const [modalShow, setModalShow] = React.useState(false);
    const [book_a_viewing_link, setViewingLink] = React.useState("");

    //const{loading, error, data} = FeaturedProperties(department, search_type);

    useEffect(()=>{
        if(Module?.tab_list?.length > 0){
            var split_tab_value = Module?.tab_list[0]?.add_tab ? Module?.tab_list[0]?.add_tab?.split("_") : []
            setDepartment(split_tab_value[0])
            setSearchType(split_tab_value[1])
        }
    },[Module])

    const handleChange = (event) =>{
        var get_tab_key = event.target.attributes.getNamedItem("data-rr-ui-event-key").value;
        var split_tab_value = get_tab_key.split("_")
        setDepartment(split_tab_value[0])
        setSearchType(split_tab_value[1])
        //console.log("get_tab_key", get_tab_key);
        //console.log("tab_key", event.target.attributes.getNamedItem("data-rr-ui-event-key").value)
    }

    useEffect(() =>{

        //console.log("department search_type", department, search_type);

        if(department === "residential" && search_type === "lettings"){
            setPropertiesList(to_let_properties)
        } else if(department === "residential" && search_type === "sold"){
            setPropertiesList(sold_properties)
        } else if(department === "residential" && search_type === "let"){
            setPropertiesList(let_properties)
        } else if(department === "auction" && search_type === "sales"){
            setPropertiesList(auction_properties)
        } else if(department === "auction" && search_type === "sold"){
            setPropertiesList(auction_sold)
        } else if(department === "students"){
            setPropertiesList(student_let_properties)
        } else if(department === "investment"){
            setPropertiesList(investment_properties)
        } else if(department === "land"){
            let properties_filter = []
            land_properties.map(item => {
                // if(item.algoliaData.building.some(item2 => item2 === "land")){
                //     properties_filter.push(item)
                // }
                properties_filter.push(item)
            })

            //console.log("properties_filter", properties_filter)
            setPropertiesList(properties_filter)
        } 
        // else if(department === "development_plot"){
        //     let properties_filter = []
        //     development_plot_properties.map(item => {
        //         properties_filter.push(item)
        //     })

        //     //console.log("properties_filter", properties_filter)
        //     setPropertiesList(properties_filter)
        // } 
        else{
            setPropertiesList(sales_properties)
        }
        
    },[department, search_type])
    
    var settings = {
        dots: false,
        arrows: true,
        infinite: properties_list?.length > 4 ? true : false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        mobileFirst: true,
        afterChange: (event) => {
            var activeDotEl = $('.features .slick-slider').find('.slick-dots').find('li.slick-active');
            if(activeDotEl.get(0)){
                activeDotEl.get(0).scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
            }
            
        },
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    dots: false,
                    arrows: true,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: properties_list?.length > 3 ? true : false,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    dots: true,
                    arrows: false,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: properties_list?.length > 2 ? true : false,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    arrows: false,
                    dots:true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: properties_list?.length > 1 ? true : false,
                },
            },

        ],
    };


    //console.log("land_properties", land_properties);

    return (
        <React.Fragment>
            <UserObjectStoreProvider>
            {Module?.tab_list?.length > 0 && 
            <section className={`features ${Module?.background_clr} ${Module?.feat_padd}`}>
                
                <Container>
                    <Row>
                        <Col>
                            <h2>{Module?.title ? Module?.title : "Featured Properties"}</h2>
                            <div className="tab-wrapper">
                                {Module?.tab_list?.length > 0 && (
                                    <Tabs
                                        defaultActiveKey={Module?.tab_list[0]?.add_tab}
                                        id="uncontrolled-tab-example"
                                        onClick={handleChange}
                                        className={`tab_count_${Module?.tab_list?.length}`}

                                        >
                                        {Module?.tab_list.map((item, index) => {
                                            return(
                                                <Tab eventKey={item.add_tab} title={item.tab_name} >
                                                    {properties_list?.length > 0 && 
                                                    <Slider {...settings}>
                                                        {properties_list?.map((item, index) => (
                                                            <PropertyCard key={index} FeaturesList={item} setModalShow={setModalShow} setViewingLink={setViewingLink} userObjects={userObjects} />
                                                        ))}
                                                    </Slider>
                                                    }
                                                </Tab>
                                            )
                                        })}                                        
                                    </Tabs>
                                )
                                }                                
                            </div>
                        </Col>
                    </Row>
                </Container>

                <Modal
                    show={modalShow}
                    onHide={() => {setModalShow(false); setViewingLink("")}}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter" className="book_a_viewing_ttl">
                            Book a Viewing
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="team_content col-md-12 col-lg-18 p-0">
                            <iframe src={book_a_viewing_link} height="600" width={"100%"} className="book_a_viewing" />
                        </div>
                    </Modal.Body>
                </Modal>

            </section>
            }
           </UserObjectStoreProvider>
        </React.Fragment>
    );
};
export default Features;
